@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h1 class="text-center">ร้านทั้งหมด</h1>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span>ค้นหา</span>
        <v-text-field v-model="search" dense hide-details outlined placeholder="ค้นหา"></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-card><v-data-table :headers="headersOrder" :items="itemsData" :search="search" :items-per-page="10"
            class="elevation-1">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn v-if="item.status == 'รอชำระเงิน'" text>
                <v-chip color="orange" dark> ยืนยันรับงาน </v-chip>
              </v-btn>
              <v-btn v-else text>
                <v-chip color="success" dark> แชท </v-chip>
              </v-btn>
            </template>
            <template v-slot:[`item.editItems`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="goToEdit(item)">mdi-account</v-icon>
                </template>
                <span>ข้อมูลผู้สมัคร</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="goToEditWork(item)">mdi-home</v-icon>
                </template>
                <span>ข้อมูลร้านค้า</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.customerName`]="{ item }">
              {{ item.user.firstName }} {{ item.user.lastName }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 'รอชำระเงิน'" color="orange" dark>{{
                item.status
                }}</v-chip>
              <v-chip v-else color="green" dark>{{ item.status }}</v-chip>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ convertDate(item.updatedAt) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { Encode } from "@/services";
import { Decode } from "@/services";
import moment from "moment";
export default {
  data() {
    return {
      search: "",
      furType: null,
      service: null,
      petTame: null,
      form: {},
      pic1: [],
      picture_1: "",
      showImage1: "",
      imageName1: "",
      pic2: [],
      picture_2: "",
      showImage2: "",
      imageName2: "",
      pic3: [],
      picture_3: "",
      showImage3: "",
      imageName3: "",
      pic4: [],
      picture_4: "",
      showImage4: "",
      imageName4: "",
      headersOrder: [
        { text: "ลำดับ", value: "index", align: "center", width: 50 },
        { text: "", value: "editItems", align: "center", width: 120 },
        // { text: "", value: "action", align: "center", width: 100 },
        {
          text: "ชื่อร้าน",
          value: "shopName",
          align: "center",
          width: 100,
        },
        {
          text: "Email",
          value: "email",
          align: "center",
          width: 100,
        },
        {
          text: "ประเภทร้าน",
          value: "serviceType",
          align: "center",
          width: 200,
        },
        {
          text: "จังหวัด",
          value: "areaService",
          align: "center",
          width: 200,
        },
        {
          text: "เขต",
          value: "areaServiceDistrict",
          align: "center",
          width: 200,
        },

        // {
        //   text: "ชื่อสัตว์เลี้ยง",
        //   value: "petName",
        //   align: "center",
        //   width: 200,
        // },
        // { text: "หนัก", value: "weight", align: "center", width: 100 },
        // { text: "ประเภทขน", value: "furType", align: "center", width: 100 },
        // { text: "พฤติกรรม", value: "petTame", align: "center", width: 100 },
        // {
        //   text: "โรคประจำตัว",
        //   value: "chronicDisease",
        //   align: "center",
        //   width: 100,
        // },
        // {
        //   text: "บริการเสริม",
        //   value: "moreService",
        //   align: "center",
        //   width: 100,
        // },
        // { text: "ที่อยู่", value: "address", align: "center", width: 300 },
        // { text: "Google Maps", value: "maps", align: "center", width: 100 },
      ],
      itemsData: [
        // {
        //   index: 1,
        //   totalPrice: '500 บาท',
        //   customerName: "สมชาย แสนดี",
        //   petName: "ขนปุย",
        //   service: "อาบน้ำ",
        //   weight: "9",
        //   furType: "ขนยาว",
        //   petTame: "ไม่ดุ",
        //   chronicDisease: "ไม่มี",
        //   moreService: "สางขน",
        //   address: "กรุงเทพมหานคร เขตราชเทวี",
        //   maps: "Maps",
        //   status: "pending",
        // },
        // {
        //   index: 2,
        //   totalPrice: '500 บาท',
        //   customerName: "แก้วตา หวานใจ",
        //   petName: "ชื่อน้อง",
        //   service: "อาบน้ำ",
        //   weight: "12",
        //   furType: "ขนสั้น",
        //   petTame: "ดุ",
        //   chronicDisease: "ไม่มี",
        //   moreService: "สางขน",
        //   address: "กรุงเทพมหานคร เขตราชเทวี",
        //   maps: "https://www.google.co.th/maps/@18.3170581,99.3986862,17z?hl=th&entry=ttu",
        //   status: "working",
        // },
      ],
    };
  },
  async created() {
    const checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("DDLotAdmin"))
    );
    this.form = checkuser.user;
    this.getAll();
  },

  methods: {
    convertDate(val) {
      return moment(val).format("DD/MM/YYYY hh:mm");
    },
    async goToEdit(item) {
      console.log(item);
      this.$router.push({
        path: "/ManageUserEdit",
        query: { id: JSON.stringify(item.id) },
      });
      // const response = await this.axios.get(
      //   `${process.env.VUE_APP_API}/transaction/${item.id}`
      // );
      // console.log("getOne", response.data);
    },
    async goToEditWork(item){
      this.$router.push({
        path: "/ManageSetWork",
        query: { id: JSON.stringify(item.id) },
      });
    },
    async getAll() {
      const checkuser = JSON.parse(
        Decode.decode(localStorage.getItem("DDLotAdmin"))
      );
      let response = {};
      response = await this.axios.get(
        `${process.env.VUE_APP_API}/partners`
      );


      console.log("getAll", response.data);
      this.itemsData = response.data.data;
      for (let i in this.itemsData) {
        this.itemsData[i].index = parseInt(i) + 1;
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage1 = URL.createObjectURL(element);
        };
      }
    },
    showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
        };
      }
    },
    showPicture3(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName3 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic3 = reader.result;
          this.showImage3 = URL.createObjectURL(element);
        };
      }
    },
    showPicture4(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName4 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic4 = reader.result;
          this.showImage4 = URL.createObjectURL(element);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    changePic3() {
      document.getElementById("picTure3").click();
    },
    changePic4() {
      document.getElementById("picTure4").click();
    },
    RemoveImage1(index, item) {
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage1 = "";
      this.imageName1 = "";
    },
    RemoveImage2(index, item) {
      this.pic2 = [];
      this.picture_2 = "";
      this.showImage2 = "";
      this.imageName2 = "";
    },
    RemoveImage3(index, item) {
      this.pic3 = [];
      this.picture_3 = "";
      this.showImage3 = "";
      this.imageName3 = "";
    },
    RemoveImage4(index, item) {
      this.pic4 = [];
      this.picture_4 = "";
      this.showImage4 = "";
      this.imageName4 = "";
    },
    cancel() {
      this.$router.push("ManageDealer");
    },
    async submit() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("userdata", user);
      const data = {
        // id: this.id,
        comPrice: this.comPrice,
        comDate: this.comDate,
        comSlipUrl: this.pic1,
        comPNDUrl: this.pic2,
        adminId: user.user.id,
        agentId: this.id,
      };
      this.$swal({
        text: "คุณต้องการยืนยันบันทึกข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // var user = JSON.parse(
          //   Decode.decode(localStorage.getItem("DDLotAdmin"))
          // );
          // const auth = {
          //   headers: { Authorization: `Bearer ${user.token}` },
          // };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/agents/createCom`,
            data,
            auth
          );
          console.log(response);

          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `บันทึกข้อมูลสำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
            // await location.reload();
            this.$router.push("ManageDealer");
          }
        }
      });
    },
  },
};
</script>
